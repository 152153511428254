export const filterlist = [
    {
        name: 'None',
        className: ''
    },
    {
        name: 'grayscale',
        className: 'grayscale'
    },
    {
        name: 'sepia',
        className: 'sepia'
    },
    {
        name: 'brighten',
        className: 'brightness-125'
    },
    {
        name: 'darken',
        className: 'brightness-75'
    },
    {
        name: 'contrast',
        className: 'contrast-125'
    },
    {
        name: 'saturation',
        className: 'saturate-125'
    },
    {
        name: 'pixelate',
        className: ''
    },
   
];